import MatriculasIndexPage from "@/Pages/Matriculas/index.vue";
import NewEditMatriculaPage from "@/Pages/Matriculas/NewEditMatriculaPage.vue";
import DesempenhoNotaAluno from "@/Pages/Matriculas/DesempenhoNotaAluno.vue";
import MensalidadeAluno from "@/Pages/Matriculas/MensalidadeAluno.vue";
import MatriculaInformacao from "@/Pages/Matriculas/informacao.vue";

export default [
  {
    path: "/matriculas/",
    name: "matriculas",
    component: MatriculasIndexPage,
  },
  {
    path: "/matriculas/create",
    name: "matriculas.create",
    component: NewEditMatriculaPage,
    props: { editing: false },
  },
  {
    path: "/matriculas/edit/:matricula_id",
    name: "matriculas.edit",
    component: NewEditMatriculaPage,
    props: { editing: true },
  },
  {
    path: "/matriculas/desempenho-de-notas-do-aluno/matricula/:matricula_id",
    name: "matriculas.desempenhoNota",
    component: DesempenhoNotaAluno,
    props: { editing: true },
  },
  {
    path: "/matriculas/mensalidades-do-aluno/matricula/:matricula_id",
    name: "matriculas.mensalidade",
    component: MensalidadeAluno,
    props: { editing: true },
  },
  {
    path: "/matricula/informacao/:matricula_id",
    name: "matriculas.informacao",
    component: MatriculaInformacao,
  },
];
