<template>
  <main-template>
    <v-container>
      <template>
        <v-row>
          <v-col cols="12">
            <h1>
              <v-btn color="primary" x-small text @click="goBack">
                <v-icon left> fa fa-angle-left </v-icon>
              </v-btn>
              Mensalidades do(a) {{ matriculas.aluno?.nomecompleto }}
            </h1>
          </v-col>
        </v-row>
      </template>
      <v-row v-if="!loadingParcelas">
        <v-col cols="12">
          <v-data-table
            :headers="table.headers"
            :items="parcelas ? parcelas : []"
            :items-per-page="15"
            :header-props="{ sortIcon: null }"
          >
            <template v-slot:item.checkbox="{ item: parcela }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="parcela.linhaDigitavel"
                    x-small
                    color="green"
                    class="white--text"
                    style="border-radius: 100px; padding-left: 21px; width: 10%"
                    v-bind="attrs"
                    v-on="on"
                    @click="consultarBoleto(parcela)"
                  >
                    <v-icon small left>fa-building</v-icon>
                  </v-btn>
                </template>
                <span>Imprimir Boleto</span>
              </v-tooltip>
            </template>

            <template v-slot:item.referencia="{ item: parcela }">
              {{ parcela.referencia | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
            </template>

            <template v-slot:item.valor="{ item: parcela }">
              {{ parcela.valor | currency }}
            </template>

            <template v-slot:item.valorpago="{ item: parcela }">
              <v-chip small :color="parcela.valorPago > 0 ? 'green' : 'red'" class="white--text">
                {{ parcela.valorpago | currency }}
              </v-chip>
            </template>

            <template v-slot:item.valordesconto="{ item: parcela }">
              {{ parcela.desconto | currency }}
            </template>

            <template v-slot:item.valoraberto="{ item: parcela }">
              <v-chip color="green" class="white--text">
                {{ calcularValorAberto(parcela) | currency }}
              </v-chip>
            </template>

            <template v-slot:item.situacao="{ item: parcela }">
              <e-situacao-parcela small :parcela="parcela" />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </main-template>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "MensalidadesPage",
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  data() {
    return {
      desconto: 0,
      mesInicio: { id: 1 },
      mesFinal: { id: 12 },
      vencimento: 15,
      parcela: {},
      matriculaSelecionada: {},
      matriculas: [],
      parcelas: [],
      loadingMatriculas: true,
      loadingParcelas: false,
      table: {
        headers: [
          { text: "Boleto", value: "checkbox" },
          { text: "Nº", value: "numero" },
          { text: "Referência", value: "referencia" },
          { text: "Valor", value: "valor" },
          { text: "Valor Pago", value: "valorpago" },
          { text: "Valor Desconto", value: "valordesconto" },
          { text: "Valor Aberto", value: "valoraberto" },
          { text: "Situação", value: "situacao", align: "center" },
        ],
        headersComParam: [
          { text: "Nº", value: "numero" },
          { text: "Referência", value: "referencia" },
          { text: "Valor", value: "valor" },
        ],
      },
    };
  },

  mounted() {
    this.loadMatriculas();
  },

  methods: {
    calcularValorAberto(parcela) {
      const diff = parcela.valor - parcela.valorpago - parcela.desconto;
      return Math.abs(diff) < 0.000001 ? 0 : diff;
    },
    async loadParcelas(matricula) {
      this.loadingParcelas = true;
      try {
        const matriculaDetalhada = await this.$services.matriculasService.getDetails(matricula.id);
        const parcelas = await this.$services.matriculasService.parcelas(
          matriculaDetalhada.parcelas
        );
        parcelas.sort((a, b) => a.numero - b.numero);

        this.parcelas = parcelas;
        this.matriculaSelecionada = { ...matriculaDetalhada };
      } catch (error) {
        this.$handleError(error);
      }
      this.loadingParcelas = false;
    },

    async loadMatriculas() {
      this.loadingMatriculas = true;
      try {
        const { matricula_id } = this.$route.params;
        const matricula = await this.$services.matriculasService.pegarMatricula(matricula_id);

        this.matriculas = matricula;
        this.loadParcelas(this.matriculas);
      } catch (error) {
        this.$handleError(error.message || error.response.data.error.message);
      }
      this.loadingMatriculas = false;
    },
    consultarBoleto(parcela) {
      this.$loaderService.open("Consultanto Boleto");
      this.$services.parcelasService
        .consultarBoleto(parcela)
        .then((response) => {
          // window.open(response.data)
        })
        .catch((err) => {
          this.$handleError(err.error);
        })
        .finally(() => {
          this.$loaderService.close();
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style>
.row-pointer:hover {
  cursor: pointer;
}
</style>
